// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .basicui-light {
    --basicui-formelement-background-color: var(--theme-white-300);
    --basicui-formelement-border-color: var(--theme-white-300);
    --basicui-formelement-background-color-hover: var(--theme-white-300);
    --basicui-formelement-border-color-hover: var(--theme-primary-transparent-200);
    --basicui-formelement-background-color-active: var(--theme-white-300);
    --basicui-formelement-border-color-active: var(--theme-primary-transparent-400);
}

.basicui-dark {
    --basicui-formelement-background-color: var(--theme-black-50);
    --basicui-formelement-border-color: var(--theme-black-50);
    --basicui-formelement-background-color-hover: var(--theme-black-50);
    --basicui-formelement-border-color-hover: var(--theme-primary-transparent-200);
    --basicui-formelement-background-color-active: var(--theme-black-50);
    --basicui-formelement-border-color-active: var(--theme-primary-transparent-400);
}

.writeup-light {
    --writeup-background-color: var(--theme-white-200);
}
.writeup-dark {
    --writeup-background-color: var(--theme-black-300);
}

.writeup-light table, .writeup-light table th, .writeup-light table td,
.writeup-dark table, .writeup-dark table th, .writeup-dark table td {
    border: none;
}

* {
    --basicui-formelement-font-weight: 300;
    --basicui-formelement-font-size: 1rem;
    --writeup-background-color: var(--basicui-formelement-background-color);
} */`, "",{"version":3,"sources":["webpack://./src/basicui-styles/custom.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAkCG","sourcesContent":["/* .basicui-light {\r\n    --basicui-formelement-background-color: var(--theme-white-300);\r\n    --basicui-formelement-border-color: var(--theme-white-300);\r\n    --basicui-formelement-background-color-hover: var(--theme-white-300);\r\n    --basicui-formelement-border-color-hover: var(--theme-primary-transparent-200);\r\n    --basicui-formelement-background-color-active: var(--theme-white-300);\r\n    --basicui-formelement-border-color-active: var(--theme-primary-transparent-400);\r\n}\r\n\r\n.basicui-dark {\r\n    --basicui-formelement-background-color: var(--theme-black-50);\r\n    --basicui-formelement-border-color: var(--theme-black-50);\r\n    --basicui-formelement-background-color-hover: var(--theme-black-50);\r\n    --basicui-formelement-border-color-hover: var(--theme-primary-transparent-200);\r\n    --basicui-formelement-background-color-active: var(--theme-black-50);\r\n    --basicui-formelement-border-color-active: var(--theme-primary-transparent-400);\r\n}\r\n\r\n.writeup-light {\r\n    --writeup-background-color: var(--theme-white-200);\r\n}\r\n.writeup-dark {\r\n    --writeup-background-color: var(--theme-black-300);\r\n}\r\n\r\n.writeup-light table, .writeup-light table th, .writeup-light table td,\r\n.writeup-dark table, .writeup-dark table th, .writeup-dark table td {\r\n    border: none;\r\n}\r\n\r\n* {\r\n    --basicui-formelement-font-weight: 300;\r\n    --basicui-formelement-font-size: 1rem;\r\n    --writeup-background-color: var(--basicui-formelement-background-color);\r\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
